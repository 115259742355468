export default {
  label: {
    variant: 'text.small',
  },
  variants: {
    inputbuttongroup: {
      bg: 'white',
      border: 0,
      borderRightWidth: '0',
      borderRadius: 'default',
      borderTopLeftRadius: 'small',
      borderBottomLeftRadius: 'small',
      flex: '1 1 0',
      py: 3,
      px: 5,
      ':focus': {
        outline: 'focus-ring-color auto 5px',
      },
    },
  },
};
