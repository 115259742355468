export default {
  variants: {
    primary: {
      fontSize: 1,
      fontWeight: 'bold',
      fontFamily: 'body',
      color: 'white',
      bg: 'primary',
      borderRadius: 'small',
      px: [6, 6, 8],
      py: 3,
      ':hover': {
        bg: 'darkgrey',
        cursor: 'pointer',
      },
    },
    inputbuttongroup: {
      borderRadius: 0,
      py: 3,
      px: 5,
      fontWeight: 'bold',
      borderTopRightRadius: 'small',
      borderBottomRightRadius: 'small',
      ':hover': {
        bg: 'darkgrey',
        cursor: 'pointer',
      },
      ':disabled': {
        bg: 'bluegrey',
        cursor: 'not-allowed',
      },
    },
  },
};
// inactive: {
//   variant: 'buttons.primary',
//   color: 'black',
//   bg: 'transparent',
//   border: '1px solid black',
//   ':hover': {
//     bg: 'transparent',
//     cursor: 'pointer',
//   },
// },
// secondary: {
//   variant: 'buttons.primary',
//   color: 'black',
//   bg: 'gray',
// },
