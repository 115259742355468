export default {
  variants: {
    default: {
      fontSize: [1, 2],
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      color: 'darkgrey',
      maxWidth: '100%', // Needed for IE11. Privacy policy would break page
      letterSpacing: '.02em',
    },
    small: {
      variant: 'text.default',
      fontSize: [0, 0],
    },
    heading: {
      variant: 'text.default',
      letterSpacing: '.05em',
      fontSize: [1, 1, 1, 1, 2],
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    h1: {
      variant: 'text.heading',
      fontSize: [6, 5, 7, 8, 10],
    },
    h2: {
      variant: 'text.heading',
      fontSize: [2, 4, 4, 4, 4],
      marginBottom: 3,
      marginTop: 4,
    },
    h3: {
      variant: 'text.heading',
      fontSize: [2, 3, 3, 3, 3],
      marginBottom: 3,
      marginTop: 4,
    },
    footer: {
      variant: 'text.small',
      whiteSpace: 'nowrap',
      color: 'bluegrey',
    },
    knockout: {
      variant: 'text.heading',
      fontSize: [10, 9, 10, 11],
      fontWeight: 'bold',
      lineHeight: 1.15,
    },
    section: {
      title: {
        variant: 'text.h1',
        textAlign: 'center',
        pb: 4,
      },
      subtitle: {
        variant: 'text.heading',
        lineHeight: 'body',
        fontWeight: 'body',
        color: 'darkgrey',
        textAlign: 'center',
        fontSize: [2, 3, 4],
      },
    },
  },
};
